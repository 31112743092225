function evalToExec(container){
    container.find('.toExec').each(function(){
        eval($(this).html());
    });
}

(function (a, b) {
    a.fn.admin_tree = function (d) {
        var c = {
            "open-icon": "fa fa-folder-open",
            "close-icon": "fa fa-folder",
            selectable: true,
            "selected-icon": "fa fa-check",
            "unselected-icon": "tree-dot"
        };
        c = a.extend({}, c, d);
        this.each(function () {
            var e = a(this);
            e.html('<div class = "tree-folder" style="display:none;">                <div class="tree-folder-header">                    <i class="' + c["close-icon"] + '"></i>                    <div class="tree-folder-name"></div>                </div>                <div class="tree-folder-content"></div>                <div class="tree-loader" style="display:none"></div>            </div>            <div class="tree-item" style="display:none;">                ' + (c["unselected-icon"] == null ? "" : '<i class="' + c["unselected-icon"] + '"></i>') + '                <div class="tree-item-name"></div>            </div>');
            e.addClass(c.selectable == true ? "tree-selectable" : "tree-unselectable");
            e.tree(c)
        });
        return this
    }
})(window.jQuery);


(function () {
    this.Theme = (function () {
        function Theme() {}
        Theme.colors = {
            white: "#FFFFFF",
            primary: "#5E87B0",
            red: "#D9534F",
            green: "#A8BC7B",
            blue: "#70AFC4",
            orange: "#F0AD4E",
            yellow: "#FCD76A",
            gray: "#6B787F",
            lightBlue: "#D4E5DE",
            purple: "#A696CE",
            pink: "#DB5E8C",
            dark_orange: "#F38630"
        };
        return Theme;
    })();
})(window.jQuery);

function getOriginalSizeOfImg(img_element) {
    var t = new Image();
    t.src = img_element.attr('src');
    return {'width':t.width,'height':t.height};
}

(function(old) {
    $.fn.attr = function() {
        if(arguments.length === 0) {
            if(this.length === 0) {
                return null;
            }

            var obj = {};
            $.each(this[0].attributes, function() {
                if(this.specified) {
                    obj[this.name] = this.value;
                }
            });
            return obj;
        }

        return old.apply(this, arguments);
    };
})($.fn.attr);


function isJoursOuvre(date){
    //Si dimanche
    if(date.getDay()==0){
        return false;
    }
    var an = date.getFullYear();
    var JourAn = new Date(an, "00", "01");
    var FeteTravail = new Date(an, "04", "01");
    var Victoire1945 = new Date(an, "04", "08");
    var FeteNationale = new Date(an,"06", "14");
    var Assomption = new Date(an, "07", "15");
    var Toussaint = new Date(an, "10", "01");
    var Armistice = new Date(an, "10", "11");
    var Noel = new Date(an, "11", "25");

    var G = an%19;
    var C = Math.floor(an/100);
    var H = (C - Math.floor(C/4) - Math.floor((8*C+13)/25) + 19*G + 15)%30;
    var I = H - Math.floor(H/28)*(1 - Math.floor(H/28)*Math.floor(29/(H + 1))*Math.floor((21 - G)/11));
    var J = (an*1 + Math.floor(an/4) + I + 2 - C + Math.floor(C/4))%7;
    var L = I - J;
    var MoisPaques = 3 + Math.floor((L + 40)/44);
    var JourPaques = L + 28 - 31*Math.floor(MoisPaques/4);
    var Paques = new Date(an, MoisPaques-1, JourPaques);
    //**var VendrediSaint = new Date(an, MoisPaques-1, JourPaques-2);**//
    var LundiPaques = new Date(an, MoisPaques-1, JourPaques+1);
    var Ascension = new Date(an, MoisPaques-1, JourPaques+39);
    //var Pentecote = new Date(an, MoisPaques-1, JourPaques+49);
    //var LundiPentecote = new Date(an, MoisPaques-1, JourPaques+50);
    var tabJourFerie = new Array(JourAn, Paques, LundiPaques, FeteTravail, Victoire1945, Ascension, FeteNationale, Assomption, Toussaint, Armistice, Noel);
    $.each(tabJourFerie,function( index, value ) {
        if(date.getMonth() == value.getMonth() && date.getFullYear() == value.getFullYear() && date.getDate() == value.getDate()){
            return false;
        }
    });
    return true;
}

function getDateFromStartAndLength(date,length,type,allDay){
    if(allDay){
        if(type=='day'){
            return new Date(date.getDate() + length);
        }else if(type=='month'){
            date.setMonth(date.getMonth()+length);
            date.setDate(date.getDate()-1);
            return date;
        }else if(type=='year'){
            date.setFullYear(date.getFullYear()+length);
            return date;
        }
    }else{
        if(type=='day'){
            var dateTmp = new Date(date);
            for(var i=1;i<length;i++){
                dateTmp.setDate(date.getDate()+i);
                if(!isJoursOuvre(dateTmp)){
                    length++;
                }
            }
            return dateTmp;
        }
    }
}